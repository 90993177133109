import Helpers from '/src/global/js/helpers/helpers.js';

/**
 * Custom event for when dropdown is opened
 * @event dropdownOpened
 * @type {Event}
 */
const dropdownOpenedEvent = new Event('dropdownOpened', {
    bubbles: true,
    composed: true,
});
/**
 * Custom event for when dropdown is opened by click
 * @event dropdownOpenedByClick
 * @type {Event}
 */
const dropdownOpenedByClickEvent = new Event('dropdownOpenedByClick', {
    bubbles: true,
    composed: true,
});
/**
 * Custom event for when dropdown is opened by hover
 * @event dropdownOpenedByHover
 * @type {Event}
 */
const dropdownOpenedByHoverEvent = new Event('dropdownOpenedByHover', {
    bubbles: true,
    composed: true,
});
/**
 * Custom event for when dropdown is hidden
 * @event dropdownHidden
 * @type {Event}
 */
const dropdownHiddenEvent = new Event('dropdownHidden', {
    bubbles: true,
    composed: true,
});
/**
 * Custom event for when dropdown is hidden by click
 * @event dropdownHiddenByClick
 * @type {Event}
 */
const dropdownHiddenByClickEvent = new Event('dropdownHiddenByClick', {
    bubbles: true,
    composed: true,
});
/**
 * Custom event for when dropdown is hidden by hover
 * @event dropdownHiddenByHover
 * @type {Event}
 */
const dropdownHiddenByHoverEvent = new Event('dropdownHiddenByHover', {
    bubbles: true,
    composed: true,
});

/**
 * Initialize dropdown functionality
 * @function
 */
function dropdownInit() {
    const dropdowns = document.querySelectorAll('.js-dropdown');

    // Stop if dropdowns not found
    if (dropdowns.length === 0) {
        return;
    }

    let currentOpenDropdown = null; // Track the currently open dropdown

    dropdowns.forEach((dropdown) => {
        // Get dropdown params
        const params = Helpers.getJsonFromAttr(dropdown.dataset.dropdown) || {};
        // Set toggler
        const toggler = dropdown.querySelector('.js-dropdown-toggler');
        // Set bar
        const bar = dropdown.querySelector('.js-dropdown-bar');

        // Stop if Toggler or Bar not found
        if (!toggler || !bar) {
            return;
        }

        const activeClass = 'js-dropdown-active';
        const position = params.position || 'bottom-left';
        dropdown.classList.add(`js-dropdown-${position}`);

        // Prevent default link behavior for dropdown togglers
        toggler.addEventListener('click', (e) => {
            if (dropdown.querySelector('.js-dropdown-bar')) {
                e.preventDefault();
            }
        });

        // Toggle dropdown on click
        toggler.addEventListener('click', () => {
            if (dropdown.classList.contains(activeClass)) {
                dropdownHide(dropdown, activeClass, toggler, 'click');
            } else {
                dropdownShow(dropdown, activeClass, toggler, 'click');
            }
        });

        // Handle hover behavior with a timeout for hiding
        let hideTimeout;

        toggler.addEventListener('mouseover', () => {
            clearTimeout(hideTimeout); // Prevent hide if hover occurs
            dropdownShow(dropdown, activeClass, toggler, 'hover');
        });

        toggler.addEventListener('mouseout', () => {
            hideTimeout = setTimeout(() => {
                dropdownHide(dropdown, activeClass, toggler, 'hover');
            }, 100);
        });

        bar.addEventListener('mouseover', () => {
            clearTimeout(hideTimeout); // Prevent hide when hovering over bar
        });

        bar.addEventListener('mouseout', () => {
            hideTimeout = setTimeout(() => {
                dropdownHide(dropdown, activeClass, toggler, 'hover');
            }, 100);
        });

        // Ensure one-click behavior on touch devices
        toggler.addEventListener('touchstart', (e) => {
            if (!dropdown.classList.contains(activeClass)) {
                e.preventDefault(); // Prevent link navigation on the first touch

                // Close the currently open dropdown if it's different from this one
                if (currentOpenDropdown && currentOpenDropdown !== dropdown) {
                    dropdownHide(
                        currentOpenDropdown,
                        activeClass,
                        currentOpenDropdown.querySelector(
                            '.js-dropdown-toggler',
                        ),
                        'click',
                    );
                }

                dropdownShow(dropdown, activeClass, toggler, 'click');
                currentOpenDropdown = dropdown; // Update the currently open dropdown
            }
        });

        // Update the document click event to handle the currently open dropdown
        document.addEventListener('click', (event) => {
            if (
                currentOpenDropdown &&
                !currentOpenDropdown.contains(event.target)
            ) {
                dropdownHide(
                    currentOpenDropdown,
                    activeClass,
                    currentOpenDropdown.querySelector('.js-dropdown-toggler'),
                    'click',
                );
                currentOpenDropdown = null; // Reset the currently open dropdown
            }
        });
    });
}

/**
 * Displays a dropdown.
 *
 * @param {HTMLElement} dropdown - The dropdown element.
 * @param {string} activeClass - The class name to activate the dropdown.
 * @param {HTMLElement} toggler - The toggler element.
 * @param {string} action - The action triggering the dropdown ('hover' or 'click').
 */
function dropdownShow(dropdown, activeClass, toggler, action = 'hover') {
    dropdown.classList.add(activeClass);
    dropdown.dispatchEvent(dropdownOpenedEvent);

    if (action === 'hover') {
        dropdown.dispatchEvent(dropdownOpenedByHoverEvent);
    } else if (action === 'click') {
        dropdown.dispatchEvent(dropdownOpenedByClickEvent);
    }

    // Update aria-expanded attribute based on activeClass
    toggler.setAttribute('aria-expanded', 'true');
}

/**
 * Hides a dropdown.
 *
 * @param {HTMLElement} dropdown - The dropdown element.
 * @param {string} activeClass - The active class for the dropdown element
 * @param {HTMLElement} toggler - The toggler element.
 * @param {string} action - The action triggering the dropdown ('hover' or 'click').
 */
function dropdownHide(dropdown, activeClass, toggler, action = 'hover') {
    dropdown.classList.remove(activeClass);
    dropdown.dispatchEvent(dropdownHiddenEvent);

    if (action === 'hover') {
        dropdown.dispatchEvent(dropdownHiddenByHoverEvent);
    } else if (action === 'click') {
        dropdown.dispatchEvent(dropdownHiddenByClickEvent);
    }

    // Update aria-expanded attribute when activeClass is removed
    toggler.setAttribute('aria-expanded', 'false');
}

export default dropdownInit;
