/**
 * Implements a filter with FacetWP integration.
 * The class initializes filter properties, static elements, and event handlers.
 * It also manages the state and UI of the filter based on FacetWP events.
 *
 * @author Dreamers of Day
 */
class FilterB1 {
    /**
     * Constructs the FilterB1 instance.
     * Initializes the filter module, properties, static elements, event handlers,
     * and dispatches a custom 'facetwp-loaded' event.
     *
     * @param {HTMLElement} module - The DOM element of the filter module.
     */
    constructor(module) {
        this.filter = module;

        this.initStaticElements();
        this.initEventHandlers();

        // Trigger selectBox initialization on FacetWP loaded for the first time.
        document.dispatchEvent(new Event('facetwp-loaded'));
    }

    /**
     * Initializes static elements within the filter module.
     */
    initStaticElements() {
        this.resetButtons = this.filter.querySelectorAll('.js-reset-filters');
        this.scrollAnchor = this.filter.querySelector('.js-scroll-anchor');
    }

    /**
     * Initializes event handlers for filter interactions and FacetWP events.
     */
    initEventHandlers() {
        // Attach scroll-to-top functionality.
        this.filter.addEventListener(
            'click',
            this.scrollIntoViewClickHandler.bind(this),
        );

        // Attach reset functionality to each reset button.
        this.resetButtons.forEach((button) => {
            button.addEventListener('click', this.resetClickHandler.bind(this));
        });

        // FacetWP event handlers.
        const addListener = document.addEventListener.bind(document);
        // Add Loader on filter is processing.
        addListener('facetwp-refresh', this.addLoader.bind(this));
        // Remove Loader on filter is completely loaded.
        addListener('facetwp-loaded', this.removeLoader.bind(this));
        // Toggle reset button visibility.
        addListener('facetwp-loaded', this.toggleResetVisibility.bind(this));
        // Initialize ChoicesJS for Facet Dropdowns.
        addListener('facetwp-loaded', this.choicesJSInitialize.bind(this));
    }

    /**
     * Handles click event to scroll into view.
     *
     * @param {Event} event - The click event object.
     */
    scrollIntoViewClickHandler(event) {
        if (!event.target.closest('.facetwp-page')) {
            return;
        }

        // Determine the scroll target and execute scroll.
        const scrollTarget = this.scrollAnchor || this.filter;
        scrollTarget.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }

    /**
     * Handles click event to reset filters.
     */
    resetClickHandler() {
        const FWP = this.getFWP();
        if (typeof FWP === 'object' && typeof FWP.reset === 'function') {
            FWP.reset();
        }
    }

    /**
     * Adds a loader animation during FacetWP refresh.
     */
    addLoader() {
        const FWP = this.getFWP();
        if (!FWP.loaded) {
            return;
        }

        const loaderSpan = document.createElement('span');
        loaderSpan.classList.add(
            'h-loader',
            '!opacity-100',
            '!absolute',
            'top-0',
            'left-0',
            'w-full',
            'h-full',
            'z-10',
        );
        const height = this.filter.clientHeight;
        this.filter.style.height = `${height}px`;
        this.filter.appendChild(loaderSpan.cloneNode());
    }

    /**
     * Removes the loader animation after FacetWP load.
     */
    removeLoader() {
        const FWP = this.getFWP();
        if (!FWP.loaded) {
            return;
        }

        const loaderSpan = this.filter.querySelector('.h-loader');
        if (loaderSpan) {
            this.filter.removeChild(loaderSpan);
        }
        this.filter.style.height = '';
    }

    /**
     * Initializes Choices.js for select dropdowns after FacetWP load.
     */
    choicesJSInitialize() {
        const FWP = this.getFWP();
        const ChoicesJS = this.getChoicesJS();

        if (!FWP.loaded || typeof ChoicesJS !== 'function') {
            return;
        }

        const facetDropdowns = this.getFacetDropdowns();
        facetDropdowns.forEach((box) => {
            // Do not initialize Choices JS if already active.
            if (
                box.dataset &&
                box.dataset.choice &&
                box.dataset.choice === 'active'
            ) {
                return;
            }
            // Initialize Choices.js for the dropdown.
            new ChoicesJS(box, {
                shouldSort: false,
                itemSelectText: '',
                allowHTML: true,
                searchEnabled: false,
            });
        });
    }

    /**
     * Toggles the visibility of the reset button based on active filters.
     */
    toggleResetVisibility() {
        const FWP = this.getFWP();
        let hasActiveFilters = false;
        for (let key in FWP.facets) {
            if (
                Object.prototype.hasOwnProperty.call(FWP.facets, key) &&
                FWP.facets[key].length > 0
            ) {
                hasActiveFilters = true;
                break;
            }
        }

        this.resetButtons.forEach((button) => {
            button.classList.toggle('reset_active', hasActiveFilters);
        });
    }

    getFWP() {
        return window.FWP || false;
    }

    getChoicesJS() {
        return window.Choices || false;
    }

    /**
     * Retrieves all dropdown elements within the filter.
     *
     * @returns {NodeList} A NodeList of dropdown elements.
     */
    getFacetDropdowns() {
        return this.filter.querySelectorAll('.facetwp-dropdown');
    }
}

export default function filterB1() {
    const filterBlocks = document.querySelectorAll(`[data-module='filterB1']`);
    if (!filterBlocks.length) {
        return;
    }
    filterBlocks.forEach((block) => {
        new FilterB1(block);
    });
}
