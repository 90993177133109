import { gsap } from 'gsap';

import SplitText from '../../../../../global/js/vendors/gsap-splittext/splittext';

gsap.registerPlugin(SplitText);
/**
 * Animates the hero section.
 */
function heroSpecialty5() {
    /**
     * Fetches the hero type from data attribute.
     *
     * @type {string}
     */
    const heroType = $('*[data-hero-type]').data('hero-type');

    /**
     * Selects breadcrumbs element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const breadcrumbs = $(`.o-hero-${heroType}__breadcrumbs-a`);

    /**
     * Selects pretitle element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const pretitle = $(`.o-hero-${heroType}__pretitle`);

    /**
     * Selects title element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const title = $(`.o-hero-${heroType} .a-title`);

    /**
     * Selects subtitle content element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const subtitleContent = $(`.o-hero-${heroType}__content-wrapper .a-text`);

    /**
     * Selects regular button element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const regularButton = $(`.o-hero-${heroType}__buttons-repeater .a-btn`);

    /**
     * Selects side image element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const sideImage = $(`.o-hero-${heroType}__img-wrapper .a-img`);

    /**
     * Selects side image cover element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const sideImageCover = $(
        `.o-hero-${heroType}__media .o-hero-${heroType}__media-cover`,
    );

    /**
     * Selects regular separator line element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const separatorLine = $(`.o-hero-${heroType}__separator-line`);

    /**
     * Timeline instance for animation.
     *
     * @type {gsap.core.Timeline}
     */
    const tl = gsap.timeline();

    if (sideImage.length) {
        // Animation for side image and side image cover
        tl.fromTo(
            sideImage,
            { scale: 1.2 },
            { duration: 1.2, scale: 1, ease: 'power4.inOut' },
            '<',
        ).to(
            sideImageCover,
            { duration: 1.2, yPercent: 100, ease: 'power4.inOut' },
            '<',
        );
    }

    if (breadcrumbs.length) {
        // Animation for breadcrumbs
        tl.fromTo(
            breadcrumbs,
            { opacity: 0 },
            { duration: 1, opacity: 1, ease: 'power4.inOut' },
            '<+=0.4',
        );
    }

    if (pretitle.length) {
        // Animation for pretitle
        tl.fromTo(
            pretitle,
            { autoAlpha: 0, yPercent: 100 },
            { duration: 1, autoAlpha: 1, yPercent: 0, ease: 'power4.inOut' },
            '<',
        );
    }

    if (title.length) {
        // Animation for title
        tl.fromTo(
            title,
            { autoAlpha: 0 },
            { duration: 0.3, autoAlpha: 1, ease: 'power4.inOut' },
            '<',
        );

        const split = new SplitText(title, {
            tag: 'span',
            type: 'lines,words',
            linesClass: 'overflow-hidden',
        });

        // Split words animation
        tl.from(
            split.words,
            {
                duration: 0.8,
                yPercent: 100,
                autoAlpha: 0,
                ease: 'power4.inOut',
                stagger: 0.1,
            },
            '<',
        );
    }

    if (subtitleContent.length) {
        // Animation for subtitleContent
        tl.fromTo(
            subtitleContent,
            { opacity: 0 },
            { duration: 1, opacity: 1, ease: 'power4.inOut' },
            '<+=0.3',
        );
    }

    if (regularButton.length) {
        // Animation for regularButton
        tl.fromTo(regularButton, { opacity: 0 }, { opacity: 1 }, '<');
    }

    if (separatorLine.length) {
        // Animation for separator line
        tl.fromTo(
            separatorLine,
            { scaleX: 0 },
            { duration: 1, scaleX: 1, ease: 'power4.inOut' },
            '<+=0.1',
        );
    }
}

export default heroSpecialty5;
