import { gsap } from 'gsap';

import SplitText from '../../../../../global/js/vendors/gsap-splittext/splittext';

gsap.registerPlugin(SplitText);

/**
 * Applies animations to elements in a hero section based on its type.
 *
 * @function heroSpecialty1
 */
const heroSpecialty1 = () => {
    // Fetching hero type from data attribute
    const heroType =
        document.querySelector('*[data-hero-type]').dataset.heroType;

    // Selecting elements based on hero type
    const title = document.querySelector(`.o-hero-${heroType} .a-title`);
    const pretitle = document.querySelector(`.o-hero-${heroType}__pretitle`);
    const sideTitle = document.querySelector(
        `.o-hero-${heroType} .a-side-title-a`,
    );
    const sideTitleTextWrapper = document.querySelector(
        `.o-hero-${heroType} .a-side-title-a__text-wrapper`,
    );
    const sideTitleLine = document.querySelector(
        `.o-hero-${heroType} .a-side-title-a__line-wrapper`,
    );
    const subtitleContent = document.querySelector(
        `.o-hero-${heroType}__content-wrapper .a-text`,
    );
    const regularButton = document.querySelector(
        `.o-hero-${heroType}__buttons-repeater .a-btn`,
    );
    const bgHeroSet = document.querySelector(
        `.o-hero-${heroType} .m-background-set-hero`,
    );
    const heroImage = document.querySelector(`.o-hero-${heroType} .a-img`);
    const heroVideo = document.querySelector(
        `.o-hero-${heroType} .m-background-set-hero__video-wrapper`,
    );

    // Creating a GSAP timeline
    const tl = gsap.timeline();

    // Animating background hero set
    tl.from(bgHeroSet, {
        duration: 1.4,
        autoAlpha: 0,
        yPercent: 100,
        ease: 'expo.inOut',
    });

    // Animating hero image if exists
    if (heroImage) {
        tl.from(
            heroImage,
            {
                duration: 1.4,
                yPercent: -80,
                scale: 1.2,
                ease: 'expo.inOut',
            },
            '<',
        );
    }

    // Animating hero video if exists
    if (heroVideo) {
        tl.from(
            heroVideo,
            {
                duration: 1.4,
                yPercent: -80,
                scale: 1.2,
                ease: 'expo.inOut',
            },
            '<',
        );
    }

    // Animating side title
    if (sideTitle) {
        tl.fromTo(
            sideTitleLine,
            { scaleY: 0 },
            { duration: 1.2, scaleY: 1, ease: 'power4.inOut' },
            '-=0.7',
        ).fromTo(
            sideTitleTextWrapper,
            { opacity: 0, xPercent: 100 },
            { duration: 1, opacity: 1, xPercent: 0, ease: 'power4.inOut' },
            '<-=0.05',
        );
    }

    // Animating pretitle
    if (pretitle) {
        tl.fromTo(
            pretitle,
            { opacity: 0, yPercent: 100 },
            { duration: 1.2, opacity: 1, yPercent: 0, ease: 'power4.inOut' },
            '<',
        );
    }

    // Animating title with SplitText plugin
    if (title) {
        const splitText = new SplitText(title, {
            tag: 'span',
            type: 'lines,words',
            linesClass: 'overflow-hidden',
        });

        tl.fromTo(
            title,
            { autoAlpha: 0 },
            { duration: 0.2, autoAlpha: 1 },
            '<',
        );

        tl.from(
            splitText.words,
            {
                duration: 1.2,
                autoAlpha: 0,
                yPercent: 100,
                stagger: 0.2,
                ease: 'power4.inOut',
            },
            '<',
        );
    }

    // Animating subtitle content
    if (subtitleContent) {
        tl.fromTo(
            subtitleContent,
            { autoAlpha: 0, yPercent: 100 },
            { duration: 1.2, autoAlpha: 1, yPercent: 0, ease: 'power4.inOut' },
            '<',
        );
    }

    // Animating regular buttons
    if (regularButton) {
        tl.fromTo(
            regularButton,
            { autoAlpha: 0 },
            { duration: 0.6, autoAlpha: 1 },
            '<',
        );
    }
};

export default heroSpecialty1;
