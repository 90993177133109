import Swiper from 'swiper';
import { A11y, Grid, Navigation, Pagination } from 'swiper/modules';

function CardsG1() {
    $('[data-module="cardsG1"]').each(function () {
        const module = $(this);

        let swiper_args = {
            modules: [A11y, Navigation, Pagination, Grid],
            slidesPerGroup: 2,
            grid: {
                rows: 3,
                fill: 'row',
            },
            navigation: {
                nextEl: '.next-slide',
                prevEl: '.prev-slide',
            },
            spaceBetween: 0,
            observer: true,
            observeParents: true,
            watchSlidesProgress: true,
            pagination: {
                el: '.js-swiper-pagination',
                clickable: true,
                bulletElement: 'button',
            },
            a11y: {
                paginationBulletMessage: 'Go to slide {{index}}',
                slideLabelMessage: '{{index}} / {{slidesLength}}',
            },
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    spaceBetween: 22,
                    grid: {
                        rows: 1,
                        fill: 'row',
                    },
                },
                600: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    grid: {
                        rows: 2,
                        fill: 'row',
                    },
                },
                1024: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                    grid: {
                        rows: 3,
                        fill: 'row',
                    },
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 46,
                    slidesPerGroup: 3,
                    grid: {
                        rows: 3,
                        fill: 'row',
                    },
                },
            },
        };
        const swiper = new Swiper(module.find('[data-slider]')[0], swiper_args);
    });
}

export default CardsG1;
