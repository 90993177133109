import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import SplitText from '../../../../../global/js/vendors/gsap-splittext/splittext';

gsap.registerPlugin(ScrollTrigger, SplitText);

/**
 * Applies animations to elements in a hero section based on its type.
 *
 * @function heroSpecialty2
 */
function heroSpecialty2() {
    // Fetching hero type from data attribute
    const heroType =
        document.querySelector('*[data-hero-type]').dataset.heroType;

    // Selecting elements based on hero type
    const title = document.querySelector(`.o-hero-${heroType} .a-title`);
    const secondaryTitle = document.querySelector(
        `.o-hero-${heroType}__secondary-title-wrapper .a-title`,
    );
    const pretitle = document.querySelector(`.o-hero-${heroType}__pretitle`);
    const sideTitle = document.querySelector(
        `.o-hero-${heroType} .a-side-title-a`,
    );
    const sideTitleTextWrapper = document.querySelector(
        `.o-hero-${heroType} .a-side-title-a__text-wrapper`,
    );
    const sideTitleLine = document.querySelector(
        `.o-hero-${heroType} .a-side-title-a__line-wrapper`,
    );
    const subtitleContent = document.querySelector(
        `.o-hero-${heroType}__content-wrapper .a-text`,
    );
    const regularButton = document.querySelector(
        `.o-hero-${heroType}__buttons-repeater .a-btn`,
    );
    const sideImage = document.querySelector(
        `.o-hero-${heroType}__img-wrapper .a-img`,
    );
    const sideImageCover = document.querySelector(
        `.o-hero-${heroType}__media .o-hero-${heroType}__media-cover`,
    );

    // Creating a GSAP timeline
    const tl = gsap.timeline();

    // Animating side title
    if (sideTitle) {
        tl.fromTo(
            sideTitleLine,
            { scaleY: 0 },
            { duration: 1.2, scaleY: 1, ease: 'power4.inOut' },
        ).fromTo(
            sideTitleTextWrapper,
            { opacity: 0, xPercent: 100 },
            { duration: 1, opacity: 1, xPercent: 0, ease: 'power4.inOut' },
            '<-=0.05',
        );
    }

    // Animating pretitle
    if (pretitle) {
        tl.fromTo(
            pretitle,
            { opacity: 0, yPercent: 100 },
            { duration: 1, opacity: 1, yPercent: 0, ease: 'power4.inOut' },
            '<+=0.2',
        );
    }

    // Animating title with SplitText plugin
    if (title) {
        const splitText = new SplitText(title, {
            tag: 'span',
            type: 'lines,words',
            linesClass: 'overflow-hidden',
        });

        tl.fromTo(
            title,
            { autoAlpha: 0 },
            { duration: 0.2, autoAlpha: 1 },
            '<',
        );

        tl.from(
            splitText.words,
            {
                duration: 1.2,
                autoAlpha: 0,
                yPercent: 100,
                stagger: 0.2,
                ease: 'power4.inOut',
            },
            '<',
        );
    }

    // Animating subtitle content
    if (subtitleContent) {
        tl.fromTo(
            subtitleContent,
            { opacity: 0, xPercent: -100 },
            { duration: 0.5, opacity: 1, xPercent: 0, ease: 'power4.inOut' },
            '<+=0.5',
        );
    }

    // Animating regular button
    if (regularButton) {
        tl.fromTo(regularButton, { opacity: 0 }, { opacity: 1 }, '<');
    }

    // Animating side image and its cover
    if (sideImage) {
        tl.fromTo(
            sideImage,
            { opacity: 0, scale: 1.2 },
            { duration: 1.2, opacity: 1, scale: 1, ease: 'power4.inOut' },
            '<',
        ).to(
            sideImageCover,
            { duration: 1.2, yPercent: 100, ease: 'power4.inOut' },
            '<',
        );
    }

    // Animating secondary title with ScrollTrigger
    if (secondaryTitle) {
        const trigger = {
            trigger: secondaryTitle,
            start: 'top 90%',
        };

        const secondaryTitleTimeline = gsap.timeline({
            scrollTrigger: trigger,
        });

        const splitText = new SplitText(secondaryTitle, {
            tag: 'span',
            type: 'lines,words',
            linesClass: 'overflow-hidden',
        });

        secondaryTitleTimeline.fromTo(
            secondaryTitle,
            { autoAlpha: 0 },
            { duration: 0.2, autoAlpha: 1 },
            '<',
        );

        secondaryTitleTimeline.from(
            splitText.words,
            {
                duration: 0.8,
                autoAlpha: 0,
                yPercent: 100,
                stagger: 0.05,
                ease: 'power4.inOut',
            },
            '<',
        );
    }
}

export default heroSpecialty2;
