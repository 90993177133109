import Swiper from 'swiper';
import { A11y, Autoplay, Grid, Navigation, Pagination } from 'swiper/modules';

/**
 * Initializes the CardsC1 Swiper instance on elements with the data attribute `data-module="cardsC1"`.
 * This function configures Swiper with dynamic options based on DOM data attributes, including autoplay.
 *
 * @module Dreamers of Day - CardsC1
 * @returns {void}
 */
function CardsC1() {
    // Iterate over each DOM element with the `data-module="cardsC1"` attribute
    $('[data-module="cardsC1"]').each(function () {
        // Store a reference to the current module element
        const module = $(this);

        // Check if autoplay is enabled based on the `data-autoplay` attribute
        const autoplayEnabled = module.data('autoplay') === 'on';

        /**
         * Swiper configuration options for CardsC1.
         * These options control layout, navigation, and conditional autoplay behavior.
         */
        const swiperOptions = {
            modules: [A11y, Navigation, Pagination, Grid], // Swiper modules used
            slidesPerView: 1, // Default number of slides visible per view
            observer: true, // Enable MutationObserver for dynamic content updates
            observeParents: true, // Monitor parent DOM elements for changes
            pagination: {
                el: '.js-swiper-pagination', // Pagination container
                clickable: true, // Enable clicking pagination bullets to navigate
                bulletElement: 'button', // Use buttons for bullets for accessibility
            },
            navigation: {
                nextEl: '.next-slide', // CSS selector for the next-slide button
                prevEl: '.prev-slide', // CSS selector for the previous-slide button
            },
            watchOverflow: true, // Hide navigation buttons if there are not enough slides
            a11y: {
                paginationBulletMessage: 'Go to slide {{index}}', // ARIA label for pagination bullets
                slideLabelMessage: '{{index}} / {{slidesLength}}', // ARIA label for slides
            },
            breakpoints: {
                // Configure layout for viewport widths >= 768px
                768: {
                    slidesPerView: 2, // Show 2 slides per view
                    spaceBetween: 10, // Add space between slides
                },
                // Configure layout for viewport widths >= 1024px
                1024: {
                    slidesPerView: 3, // Show 3 slides per view
                    spaceBetween: 0, // No space between slides
                },
            },
        };

        // Add autoplay functionality if it is enabled via the `data-autoplay` attribute
        if (autoplayEnabled) {
            swiperOptions.modules.push(Autoplay); // Add Autoplay module
            swiperOptions.autoplay = {
                delay: 5000, // Set autoplay delay to 5 seconds
            };
        }

        // Initialize Swiper instance for the module
        const swiper = new Swiper(
            module.find('[data-slider]')[0], // Find the slider element within the module
            swiperOptions, // Pass the defined options
        );
    });
}

export default CardsC1;
