/**
 * Handles the translation widget functionality.
 */
function widgetTranslation() {
    // Language Widget Blocks
    const widget = '.js-widget-translation';
    const buttons = $('.language-button-link-icon');

    // Filter the buttons to find the one with "Language" text
    const languageButton = buttons.filter(function () {
        return $(this).attr('href') === '#language';
    });

    // Bind the click event handler to the language button
    languageButton.on('click', function (e) {
        e.preventDefault();
        const widgetObj = $(widget);

        // Toggle the "active" class on the widget
        if (widgetObj.hasClass('active')) {
            widgetObj.removeClass('active');
        } else {
            widgetObj.addClass('active');
        }
    });

    /**
     * Remove the active class from the widget if the click is outside the widget or header button.
     * @param {Event} e - The click event.
     */
    $(document).on('click', function (e) {
        if (
            !$(e.target).closest(widget).length && // Click is outside the widget
            $(widget).hasClass('active') && // Widget is active
            !$(e.target).closest(languageButton).length // Click is not on the language button
        ) {
            $(widget).removeClass('active'); // Remove the "active" class from the widget
        }
    });

    const languageSwitchersBlocks = document.querySelectorAll(
        `.js-google-translate-switcher`,
    );

    if (languageSwitchersBlocks.length) {
        // Looping through all of them (we can have several of them)
        [...languageSwitchersBlocks].map((languageSwitcherBlock) => {
            /**
             * Handle click event on the language switcher block.
             * @param {Event} event - The click event.
             */
            languageSwitcherBlock.addEventListener('click', (event) => {
                // Click Target
                const target = event.target;
                // Language switcher
                const button = target.closest('a');
                // If we click out of button - do nothing
                if (!button) {
                    return;
                }

                // Preventing default behaviour
                event.preventDefault();

                // Language value of this button
                const chosenLanguage = button.getAttribute('data-href');
                if (!chosenLanguage) {
                    return;
                }

                // Google Translate Select
                const googleTranslateSelect =
                    document.querySelector('.goog-te-combo');
                if (!googleTranslateSelect) {
                    return;
                }

                // Set the chosen value
                googleTranslateSelect.value = chosenLanguage;
                // Dispatching event and allow google to translate the page
                googleTranslateSelect.dispatchEvent(new Event('change'));
            });
        });
    }
}

export default widgetTranslation;
