/**
 * Handles the translation widget functionality.
 */
function widgetTranslationA() {
    // Language Widget Blocks
    const widget = document.querySelector('.js-widget-translation');

    if (!widget) {
        return;
    }

    const toggler = document.querySelector('.js-widget-translation-toggler');
    const languageSwitchersBlocks = document.querySelectorAll(
        `.js-google-translate-switcher`,
    );

    /**
     * Update the tabindex attribute of anchor tags within languageSwitchersBlocks.
     * If setTabindex is true, sets the tabindex attribute to '0', allowing keyboard focus.
     * If setTabindex is false, removes the tabindex attribute, making them not focusable by keyboard.
     *
     * @param {boolean} setTabindex - A boolean flag to determine whether to set or remove the tabindex attribute.
     */
    function updateTabindex(setTabindex) {
        languageSwitchersBlocks.forEach(function (block) {
            const anchorTags = block.querySelectorAll('a');
            anchorTags.forEach((tag) => {
                if (setTabindex) {
                    tag.setAttribute('tabindex', '0');
                } else {
                    tag.removeAttribute('tabindex');
                }
            });
        });
    }

    /**
     * Toggle the active class on the widget when the toggler is clicked.
     */
    function toggleWidget() {
        if (widget.classList.contains('active')) {
            widget.classList.remove('active');
            // Remove tabindex attribute
            updateTabindex(false);
        } else {
            widget.classList.add('active');
            // Set tabindex attribute
            updateTabindex(true);
        }
    }

    // Add click event listener to the toggler
    toggler.addEventListener('click', toggleWidget);

    // Add keydown event listener to the toggler
    toggler.addEventListener('keydown', function (event) {
        // Chec if Enter key was pressed
        if (event.key === 'Enter' || event.keyCode === 13) {
            // If it's a keydown event but not the Enter key, exit the function
            if (
                event.type === 'keydown' &&
                event.key !== 'Enter' &&
                event.keyCode !== 13
            ) {
                return;
            }
            toggleWidget();
        }
    });

    /**
     * Remove the active class from the widget if the click is outside the widget or header button.
     * @param {Event} e - The click event.
     */
    document.addEventListener('click', function (e) {
        if (!widget.contains(e.target) && widget.classList.contains('active')) {
            widget.classList.remove('active');
        }
    });

    if (languageSwitchersBlocks.length) {
        // Looping through all of them (we can have several of them)
        [...languageSwitchersBlocks].map((languageSwitcherBlock) => {
            /**
             * Handle click event on the language switcher block.
             * @param {Event} event - The click event.
             */
            function handleLanguageSwitch(event) {
                // Only proceed for click or 'Enter' keydown events
                if (
                    event.type === 'click' ||
                    (event.type === 'keydown' && event.key === 'Enter')
                ) {
                    // Click Target or Target of keydown event
                    const target = event.target;

                    // Language switcher
                    const button = target.closest('span[data-href]');

                    // If we click out of button or focus is not on the button - do nothing
                    if (!button) {
                        return;
                    }

                    // Preventing default behaviour
                    event.preventDefault();

                    // Language value of this button
                    const chosenLanguage = button.getAttribute('data-href');
                    // If there's no language set for this button - do nothing
                    if (!chosenLanguage) {
                        return;
                    }

                    // Google Translate Select
                    const googleTranslateSelect =
                        document.querySelector('.goog-te-combo');
                    // If the Google Translate select element is not found - do nothing
                    if (!googleTranslateSelect) {
                        return;
                    }

                    // Set the chosen value
                    googleTranslateSelect.value = chosenLanguage;
                    // Dispatching 'change' event to trigger the translation
                    googleTranslateSelect.dispatchEvent(new Event('change'));
                }
            }

            // Add click event listener to each language switcher block
            languageSwitcherBlock.addEventListener(
                'click',
                handleLanguageSwitch,
            );

            // Add keydown event listener for 'Enter' key to each language switcher block
            languageSwitcherBlock.addEventListener(
                'keydown',
                handleLanguageSwitch,
            );
        });
    }
}

export default widgetTranslationA;
