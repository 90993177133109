import MicroModal from 'micromodal';
/**
 * CustomMicroModal enhances MicroModal interactions by automatically initializing modals with custom
 * configurations for accessibility and UX. It handles modal show/close events, adds a body class when
 * modals are open, and intercepts clicks on specific anchor tags to show modals instead of navigating.
 *
 * Key Features:
 * - Custom event dispatching on modal show/close.
 * - Conditional modal opening based on anchor href attributes.
 * - Integration with MicroModal library for modal management.
 *
 * Designed for single instantiation per page, it requires MicroModal to be included in the project.
 *
 * Author: Dreamers of Day
 */
class CustomMicroModal {
    constructor() {
        this.initModal();
        this.registerModalOpener();
    }

    /**
     * Initializes MicroModal with custom show and close behaviors,
     * including adding a custom class to the body and dispatching custom events.
     */
    initModal() {
        MicroModal.init({
            onShow: (modal) => {
                // Adds a custom class to the body when the modal is shown
                document.body.classList.add('l-body--modal-open');
                // Dispatches a custom 'MicroModal.show' event
                document.dispatchEvent(
                    new CustomEvent('MicroModal.show', {
                        detail: { modal },
                    }),
                );
            },
            onClose: (modal) => {
                // Removes the custom class from the body when the modal is closed
                document.body.classList.remove('l-body--modal-open');
                // Dispatches a custom 'MicroModal.close' event
                document.dispatchEvent(
                    new CustomEvent('MicroModal.close', {
                        detail: { modal },
                    }),
                );
            },
            disableScroll: true,
            awaitOpenAnimation: false,
            awaitCloseAnimation: true,
        });
    }

    /**
     * Registers a click event listener to open modals based on link href attributes.
     * Only processes links with hrefs starting with '#modal-'.
     */
    registerModalOpener() {
        document.addEventListener('click', (e) => {
            const target = e.target.closest('a');
            if (!target || !this.shouldOpenModal(target)) {
                return false;
            }
            e.preventDefault();

            const href = target.getAttribute('href');
            MicroModal.show(href.replace('#', ''), { disableScroll: true });
        });
    }

    /**
     * Determines if the clicked link should trigger opening a modal.
     *
     * @param {HTMLElement} target - The clicked link element.
     * @returns {boolean} True if the modal should be opened; otherwise, false.
     */
    shouldOpenModal(target) {
        const href = target.getAttribute('href');
        if (!href || !href.startsWith('#modal-')) {
            return false;
        }
        if (
            typeof MicroModal === 'undefined' ||
            !document.querySelector(href)
        ) {
            console.log(`Popup with id "${href}" doesn't exist`);
            return false;
        }

        return true;
    }
}
export default function customMicromodalCall() {
    new CustomMicroModal();
}
