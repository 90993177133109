import { gsap } from 'gsap';

import SplitText from '../../../../../global/js/vendors/gsap-splittext/splittext';

gsap.registerPlugin(SplitText);

/**
 * Animates the hero section.
 */
function heroSpecialty4() {
    /**
     * Fetches the hero type from data attribute.
     *
     * @type {string}
     */
    const heroType = $('*[data-hero-type]').data('hero-type');

    /**
     * Selects pretitle element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const pretitle = $(`.o-hero-${heroType}__pretitle`);

    /**
     * Selects title element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const title = $(`.o-hero-${heroType} .a-title`);

    /**
     * Selects subtitle content element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const subtitleContent = $(`.o-hero-${heroType}__content-wrapper .a-text`);

    /**
     * Selects regular button element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const regularButton = $(`.o-hero-${heroType}__buttons-repeater .a-btn`);

    /**
     * Selects bottom image element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const bottomImage = $(`.o-hero-${heroType}__img-wrapper .a-img`);

    /**
     * Selects bottom image cover element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const bottomImageCover = $(`.o-hero-${heroType}__img-cover`);

    /**
     * Selects bottom video element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const bottomVideo = $(`.o-hero-${heroType}__video-wrapper`);

    /**
     * Selects bottom video cover element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const bottomVideoCover = $(`.o-hero-${heroType}__video-cover`);

    /**
     * Timeline instance for animation.
     *
     * @type {gsap.core.Timeline}
     */
    const tl = gsap.timeline();

    if (pretitle.length) {
        // Animation for pretitle
        tl.fromTo(
            pretitle,
            { opacity: 0, yPercent: -100 },
            { duration: 1, opacity: 1, yPercent: 0, ease: 'power4.inOut' },
            '<',
        );
    }

    if (title.length) {
        // Animation for title
        tl.fromTo(
            title,
            { autoAlpha: 0 },
            { duration: 0.3, autoAlpha: 1, ease: 'power4.inOut' },
            '<',
        );

        const split = new SplitText(title, {
            tag: 'span',
            type: 'chars,words',
            from: 'center',
            wordsClass: 'overflow-hidden',
        });

        // Split letter animation
        tl.from(
            split.chars,
            {
                duration: 1.2,
                yPercent: 100,
                ease: 'power4.inOut',
                stagger: {
                    amount: 0.4,
                    from: 'center',
                },
            },
            '<',
        );
    }

    if (subtitleContent.length) {
        // Animation for subtitleContent
        tl.fromTo(
            subtitleContent,
            { opacity: 0 },
            { duration: 0.5, opacity: 1, ease: 'power4.inOut' },
            '<+=1',
        );
    }

    if (bottomImage.length) {
        // Animation for bottom image and bottom image cover
        tl.fromTo(
            bottomImage,
            { scale: 1.2 },
            { duration: 1.6, scale: 1, ease: 'power4.inOut' },
            '<',
        ).to(
            bottomImageCover,
            { duration: 1.6, yPercent: 100, ease: 'power4.inOut' },
            '<',
        );
    }

    if (bottomVideo.length) {
        // Animation for bottom video and bottom video cover
        tl.fromTo(
            bottomVideo,
            { scale: 1.2 },
            { duration: 2, scale: 1, ease: 'power4.inOut' },
            '<',
        ).to(
            bottomVideoCover,
            { duration: 2, yPercent: 100, ease: 'power4.inOut' },
            '<',
        );
    }

    if (regularButton.length) {
        // Animation for regularButton
        tl.fromTo(regularButton, { opacity: 0 }, { opacity: 1 }, '<');
    }
}

export default heroSpecialty4;
