/**
 * @fileoverview Provides functionality to manage primary navigation links.
 * @module navPrimary
 */

/**
 * Adds event listeners to all level-0 navigation links with `href="#"` to prevent default navigation behavior.
 *
 * - This ensures that clicking on these links does not unintentionally navigate to the top of the page.
 * - The function specifically targets elements with the class `.m-nav__link--level-0`.
 * - If such a link has `href="#"`, it will intercept the click event and call `preventDefault`.
 *
 * @function navPrimaryA1
 * @author Dreamers of Day
 */
function navPrimaryA1() {
    // Select all primary navigation links with the level-0 class.
    const navLinks = document.querySelectorAll('.m-nav__link--level-0');

    // Iterate over each navigation link.
    navLinks.forEach((link) => {
        // Check if the link's `href` attribute is exactly "#".
        if (link.getAttribute('href') === '#') {
            // Add a click event listener to the link to prevent its default action.
            link.addEventListener('click', (event) => {
                // Prevents the browser's default navigation when the link is clicked.
                event.preventDefault();
            });
        }
    });
}

// Export the function as the default export of this module.
export default navPrimaryA1;
