/**
 * Initializes the search bar functionality.
 */
const searchBar = () => {
    /**
     * jQuery object representing the search bar element.
     * @type {JQuery<HTMLElement>}
     */
    const searchBar = $('.m-search-bar');

    /**
     * jQuery object representing the autocomplete element.
     * @type {JQuery<HTMLElement>}
     */
    const algoliaAutocomplete = $('.algolia-autocomplete');

    /**
     * Flag indicating whether the search bar is active.
     * @type {boolean}
     */
    let isSearchBarActive = searchBar.hasClass('m-search-bar--active');

    /**
     * Toggles the search bar visibility and behavior.
     */
    const toggleSearchBar = () => {
        // Check if the search bar is currently visible
        if (!isSearchBarActive) {
            // Logic to show the search bar
            $('.js-header-search-toggle').addClass('a-button-search--active');
            searchBar.slideDown(400, () => {
                searchBar.addClass('m-search-bar--active');
                $('body').addClass('h-scroll-lock');
                if (algoliaAutocomplete.css('display') === 'block') {
                    algoliaAutocomplete.css('display', 'none');
                }
                $('.m-search-bar__search-field').focus();
                isSearchBarActive = true; // Update the flag here after showing the search bar
            });
        } else {
            // Logic to hide the search bar
            searchBar.slideUp(400, () => {
                $('.js-header-search-toggle').removeClass(
                    'a-button-search--active',
                );
                searchBar.removeClass('m-search-bar--active');
                if (!$('.js-slide-out').hasClass('m-slide-out--active')) {
                    $('body').removeClass('h-scroll-lock');
                }
                isSearchBarActive = false; // Update the flag here after hiding the search bar
            });
        }
    };

    /**
     * Handles the keyup event of the search field.
     */
    const handleSearchFieldKeyup = () => {
        const searchField = $('.m-search-bar__search-field');
        const popularSearches = $('.m-popular-searches');
        const searchContainer = searchField.closest('.m-search-bar--1');

        if (searchField.val().length) {
            popularSearches.css('display', 'none');
            searchContainer.addClass('m-search-bar--focus');
        } else {
            popularSearches.css('display', 'block');
            searchContainer.removeClass('m-search-bar--focus');
        }
    };

    /**
     * Handles closing the search bar when clicking outside or tabbing away.
     * @param {Event} e - The event object (either mouseup or keyup).
     */
    const handleCloseEvent = (e) => {
        // Check for mouseup specific conditions
        if (
            e.type === 'mouseup' &&
            !searchBar.is(e.target) &&
            searchBar.has(e.target).length === 0
        ) {
            closeSearchBar();
        }
        // Check for keyup and specific key (Tab)
        else if (e.type === 'keyup' && e.key === 'Tab') {
            const activeElement = document.activeElement; // Get the currently focused element
            // Check if the new focused element is outside the searchBar
            if (
                !$(searchBar).has(activeElement).length &&
                !$(searchBar).is(activeElement)
            ) {
                setTimeout(closeSearchBar, 250);
            }
        }
    };

    /**
     * Closes the search bar and performs related DOM manipulations.
     */
    const closeSearchBar = () => {
        if (searchBar.hasClass('m-search-bar--active')) {
            searchBar.slideUp(400, () => {
                searchBar.removeClass('m-search-bar--active');
                if (!$('.js-slide-out').hasClass('m-slide-out--active')) {
                    $('body').removeClass('h-scroll-lock');
                }
                isSearchBarActive = false; // Update search bar active state
            });
        }
    };

    $('.js-header-search-toggle').on('click', toggleSearchBar);
    $('.m-search-bar__search-field').keyup(handleSearchFieldKeyup);
    $(document).on('mouseup', handleCloseEvent);
    $(document).on('keyup', handleCloseEvent);
};

export default searchBar;
