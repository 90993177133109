class CollapsableBlock {
    static MOB_MAX_WIDTH = 767;

    container = null;
    section = null;
    image = null;

    constructor(block) {
        if (!block) {
            return;
        }
        this.container = block;
        this.section = block.closest('section.o-block');
        if (!this.section) {
            return;
        }
        this.image = this.section.querySelector('img.img__m-background-set');
        if (!this.image) {
            return;
        }
        this.updateImageHeight(); // on block init.
        window.addEventListener('resize', this.updateImageHeight.bind(this)); // on block resize.
    }

    updateImageHeight() {
        if (window.innerHeight <= CollapsableBlock.MOB_MAX_WIDTH) {
            return;
        }
        const activeAccordion = this.section.querySelectorAll(
            '.js-accordion_group[data-active="1"]',
        );
        let activeAccHeight = 0;
        activeAccordion.forEach((acc) => {
            const contentBlock = acc.querySelector('.js-accordion_text');
            if (contentBlock) {
                activeAccHeight += contentBlock.offsetHeight;
            }
        });
        let height =
            this.section.offsetHeight - activeAccHeight + this.getThreshold();

        this.image.style.height = `${Math.max(
            height,
            this.section.offsetHeight,
        )}px`;
    }

    getThreshold() {
        const windowWidth = window.innerWidth;
        if (windowWidth <= CollapsableBlock.MOB_MAX_WIDTH) {
            return 0;
        }

        /**
         * Try to calculate threshold depending on the current window width.
         * The main idea here is that the same content block will have different height depending on its width (which eventually depends on the window width).
         * Therefore, the threshold value will differ on various resolutions.
         * To reflect such dependency, there is a function, that returns an approximate content block height depending on window.innerWidth value.
         *
         * A random block with content on different resolutions:
         * res: 768px - height:200px
         * res: 1440px - height: 150px
         * (x-x1)/(x2-x1) = (y-y1)/(y2-y1)
         * (x-768)/(1440-768) = (y-200)/(150-200)
         * y = - 0.074x + 257.14
         *
         * It gives a good rough value, but it does not consider a number of tags within this block and some other parameters. However, it's enough for now.
         */

        return (
            -1 * windowWidth * 0.074 + 257.14 + (this.buttonExists() ? 50 : 0)
        );
    }

    buttonExists() {
        return (
            this.section.querySelectorAll(
                '.js-accordion_text .m-accordion-a__button-wrapper',
            ).length > 0
        );
    }
}

const collapsableA1 = () => {
    const accWrapper = document.querySelectorAll(
        `[data-module="collapsableA1"]`,
    );
    accWrapper.forEach((block) => {
        new CollapsableBlock(block);
    });
};

export default collapsableA1;
