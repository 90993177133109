import Swiper from 'swiper';
import { Grid, Navigation, Pagination } from 'swiper/modules';

function CardsD1() {
    $('[data-module="cardsD1"]').each(function () {
        const module = $(this);

        const swiper = new Swiper(module.find('[data-slider]')[0], {
            modules: [Navigation, Pagination, Grid],
            slidesPerGroup: 3,
            slidesPerView: 3,
            grid: {
                rows: 2,
                fill: 'row',
            },
            observer: true,
            observeParents: true,
            watchSlidesProgress: true,
            pagination: {
                el: '.js-swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.next-slide',
                prevEl: '.prev-slide',
            },
            watchOverflow: true,
            breakpoints: {
                0: {
                    slidesPerGroup: 1,
                    slidesPerView: 1,
                    spaceBetween: 0,
                    grid: {
                        rows: 1,
                        fill: 'row',
                    },
                },
                375: {
                    slidesPerGroup: 2,
                    slidesPerView: 2,
                    spaceBetween: 0,
                    grid: {
                        rows: 2,
                        fill: 'row',
                    },
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                    grid: {
                        rows: 2,
                        fill: 'row',
                    },
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 38,
                    grid: {
                        rows: 2,
                        fill: 'row',
                    },
                },
            },
        });

        swiper.on('progress', function (e, progress) {
            if (progress <= 0) {
                module
                    .find('.js-slider-prev')
                    .addClass('a-btn-pagination--disabled');
            } else {
                module
                    .find('.js-slider-prev')
                    .removeClass('a-btn-pagination--disabled');
            }

            if (progress >= 1) {
                module
                    .find('.js-slider-next')
                    .addClass('a-btn-pagination--disabled');
            } else {
                module
                    .find('.js-slider-next')
                    .removeClass('a-btn-pagination--disabled');
            }
        });

        swiper.on('resize', function () {
            if (swiper.isBeginning && swiper.isEnd) {
                module.find('.swiper-pagination-bullets').addClass('!hidden');
            } else {
                module
                    .find('.swiper-pagination-bullets')
                    .removeClass('!hidden');
            }
        });

        if (swiper.isBeginning && swiper.isEnd) {
            module.find('.swiper-pagination-bullets').addClass('!hidden');
        }

        if (swiper.isBeginning) {
            module
                .find('.js-slider-prev')
                .addClass('a-btn-pagination--disabled');
        }

        if (swiper.isEnd) {
            module
                .find('.js-slider-next')
                .addClass('a-btn-pagination--disabled');
        }
    });
}

export default CardsD1;
