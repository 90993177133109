import Swiper from 'swiper';
import { A11y, Autoplay, Grid, Navigation, Pagination } from 'swiper/modules';

/**
 * Initializes the CardsA2 Swiper instance on elements with the data attribute `data-module="cardsA2"`.
 * This function sets up Swiper options based on DOM data attributes, including conditional autoplay.
 *
 * @module Dreamers of Day - CardsA2
 * @returns {void}
 */
function CardsA2() {
    // Select each DOM element with the attribute `data-module="cardsA2"`
    $('[data-module="cardsA2"]').each(function () {
        // Store reference to the jQuery-selected module element
        const module = $(this);

        // Determine autoplay state based on the data attribute; if 'on', enable autoplay
        const autoplayEnabled = module.data('autoplay') === 'on';

        /**
         * Swiper configuration options for CardsA2
         * Configures modules, layout, navigation, accessibility, and conditional autoplay.
         */
        const swiperOptions = {
            modules: [A11y, Navigation, Pagination, Grid],
            slidesPerGroup: 2, // Number of slides to advance per group
            slidesPerView: 2, // Number of slides visible per view
            grid: {
                rows: 2, // Number of rows in the grid layout
                fill: 'row', // Fill the grid by rows
            },
            spaceBetween: 0, // Space between slides
            observer: true, // Enable observing slide changes
            observeParents: true, // Observe changes in parent elements
            watchSlidesProgress: true, // Monitor slide progress for effects
            pagination: {
                el: '.js-swiper-pagination', // Pagination element
                clickable: true, // Enable clickable pagination
                bulletElement: 'button', // Use buttons for bullets (accessibility)
            },
            navigation: {
                nextEl: '.next-slide', // Next slide navigation element
                prevEl: '.prev-slide', // Previous slide navigation element
            },
            a11y: {
                paginationBulletMessage: 'Go to slide {{index}}', // ARIA label for pagination bullets
                slideLabelMessage: '{{index}} / {{slidesLength}}', // ARIA label for slide indicators
            },
            breakpoints: {
                // Configure layout at viewport width >= 1024px
                1024: {
                    slidesPerView: 2,
                    grid: {
                        rows: 2,
                        fill: 'row',
                    },
                },
                // Configure layout at viewport width >= 1200px
                1200: {
                    slidesPerView: 4,
                    grid: {
                        rows: 1,
                        fill: 'row',
                    },
                },
            },
        };

        // If autoplay is enabled, modify swiperOptions to add Autoplay module
        if (autoplayEnabled) {
            swiperOptions.modules.push(Autoplay);
            swiperOptions.autoplay = {
                delay: 5000, // Set autoplay delay to 5 seconds
            };
            swiperOptions.slidesPerGroup = 1; // Change slides per group to 1 for autoplay
        } else {
            swiperOptions.slidesPerGroup = 2; // Set slides per group to 2 if not autoplaying
        }

        // Instantiate Swiper with the defined options, targeting the element with data-slider
        const swiper = new Swiper(
            module.find('[data-slider]')[0], // Get the DOM element for Swiper
            swiperOptions, // Apply swiper options
        );
    });
}

export default CardsA2;
