/**
 * Toggle mobile navigation dropdowns.
 *
 * Sets up click event handlers for menu items with children and the mobile navigation dropdown
 * to enable toggling behavior. It prevents the default action for menu items with children,
 * toggles the visibility and appearance of the mobile navigation dropdown, and manages the
 * active class and plus toggler visibility.
 *
 * @author Dreamers of Day
 */
function toggleMobileNavigationDropdowns() {
    const componentClass = '.js-nav-mobile';
    const selector = '.js-nav-drop';
    const activeClass = 'is-active';
    const linkItemHasChildren = 'a.menu-item-has-children';

    // Scope children only to those under .js-nav-mobile
    const scopeElement = document.querySelector(componentClass);

    /**
     * Handles click event on menu items with children.
     *
     * Prevents the default action of the event and simulates a click on the next sibling
     * if it matches the dropdown selector.
     *
     * @param {Event} event - The click event.
     */
    function handleMenuItemClick(event) {
        event.preventDefault();
        const nextSibling = event.target.nextElementSibling;

        // Check if the next sibling matches the dropdown selector and trigger click
        if (nextSibling && nextSibling.matches(selector)) {
            nextSibling.click();
        }
    }

    /**
     * Toggles the mobile navigation dropdown on click.
     *
     * Toggles the visibility and appearance of the mobile navigation dropdown, manages
     * the active class, and toggles the plus toggler's appearance.
     */
    function toggleDropdown() {
        const currentElement = this;

        // Toggle activeClass on the clicked element
        currentElement.classList.toggle(activeClass);
        currentElement.closest('.js-dropdown').classList.toggle(activeClass);

        // Toggle visibility of the next sibling with class 'm-nav__sub-menu'
        const subMenu = currentElement.nextElementSibling;
        if (subMenu && subMenu.classList.contains('m-nav__sub-menu')) {
            // TODO: Replace jQuery method with vanilla JS for slide toggle
            $(subMenu).slideToggle();
        }

        // Toggle class 'a-plus-toggler--opened' on the child element with class 'js-plus-toggler'
        const plusToggler = currentElement.querySelector('.js-plus-toggler');
        if (plusToggler) {
            plusToggler.classList.toggle('a-plus-toggler--opened');
        }
    }

    // Attach click event to menu items with children, excluding when class 'mobile-enable-parent-link' is present
    if (!scopeElement.classList.contains('js-mobile-enable-parent-link')) {
        const linkItems = scopeElement.querySelectorAll(linkItemHasChildren);
        linkItems.forEach((item) => {
            item.addEventListener('click', handleMenuItemClick);
        });
    }

    // Attach click event to the mobile navigation dropdown
    const dropdowns = scopeElement.querySelectorAll(selector);
    dropdowns.forEach((dropdown) => {
        // Click event
        dropdown.addEventListener('click', toggleDropdown);
        // Keydown event for "Enter" key
        dropdown.addEventListener('keydown', (event) => {
            if (event.key === 'Enter' || event.keyCode === 13) {
                event.preventDefault();
                toggleDropdown.call(dropdown);
            }
        });
    });

    // Add 'is-active' class to elements with both '.m-nav__item--level-0' and '.current_page_parent' classes
    const currentPageParentItems = scopeElement.querySelectorAll(
        '.m-nav__item--level-0.current_page_parent',
    );
    currentPageParentItems.forEach((item) => {
        item.classList.add(activeClass);
    });
}

export default toggleMobileNavigationDropdowns;
