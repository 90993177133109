/**
 *  * Algolia Search Enhancements
 *  *
 *  * This file encapsulates a series of functions designed to enhance search functionalities
 *  * using Algolia's search services. It covers the initialization and configuration of autocomplete
 *  * features, prevention of form submissions for search fields, and the processing of search results
 *  * to include safe, highlighted, and formatted output.
 *
 * Original JS file is located.
 * wp-content/plugins/wp-search-with-algolia/templates/autocomplete.php
 *
 * Events :
 * autocomplete:selected:       This event is triggered when a suggestion is selected from the autocomplete dropdown. In your example, you're redirecting the user to the selected suggestion's permalink.
 * autocomplete:redrawn:        This event is triggered when the autocomplete dropdown is redrawn. This might occur when there are changes in the suggestions or when the dropdown is opened or closed.
 * autocomplete:opened:         Triggered when the autocomplete dropdown is opened.
 * autocomplete:closed:         Triggered when the autocomplete dropdown is closed.
 * autocomplete:cursorchanged:  Triggered when the cursor in the autocomplete dropdown is moved to a different suggestion.
 * autocomplete:cursorremoved:  Triggered when the cursor is removed from the autocomplete dropdown (for example, when the user clicks outside the dropdown).
 * autocomplete:shown:          Triggered when the autocomplete dropdown is shown.
 * autocomplete:hidden:         Triggered when the autocomplete dropdown is hidden.
 * autocomplete:destroy:        Triggered when the autocomplete instance is destroyed.
 * autocomplete:updated:        Triggered when the autocomplete dropdown is updated with new suggestions or when its content changes in any way.
 * autocomplete:cursorselected: Triggered when a suggestion is selected using the keyboard cursor keys.
 * autocomplete:cursorremoved:  Triggered when the cursor is removed from the autocomplete dropdown without selecting a suggestion.
 * autocomplete:empty:          Triggered when the autocomplete dropdown is opened, but there are no suggestions to display.
 * autocomplete:cursorentered:  Triggered when the cursor enters the autocomplete dropdown.
 *
 * @example of using Events:
 * algoliaAutocomplete(input, config, sources)
     .on('autocomplete:selected', ( e, suggestion ) => {});
 *
 * @author Dreamers of Day
 */
(function() {
    let client;
    const algolia = window.algolia ?? null;
    const wp = window.wp ?? null;

    const autocompleteSelector = 'js-algolia-autocomplete';

    /* Setup autocomplete.js sources */
    let sources = [];

    let searchInputs;

    /**
     * Algolia hits source method.
     *
     * This method defines a custom source to use with autocomplete.js.
     *
     * @param index
     * @param params
     */
    let algoliaHitsSource = (index, params) => {
        return (query, callback) => {
            index
                .search( query, params )
                .then( function( response ) {
                    callback( response.hits, response );
                })
                .catch( function( error ) {
                    callback( [] );
                });
        }
    }

    function initAlgoliaCustomization() {
        if (typeof algoliasearch !== 'function' || !algolia || typeof wp !== 'object') {
            return;
        }

        /* Initialize Algolia client */
        client = algoliasearch( algolia.application_id, algolia.search_api_key );

        /* Setup dropdown menus */
        const inputSelector = `input[name='q']:not(.no-autocomplete):not(#adminbar-search), ${algolia.autocomplete.input_selector}`;
        searchInputs = document.querySelectorAll(inputSelector);

        //
        forbidSubmittingForm();
        processAutocompleteSources();
        initializeSearchInputs();
    }

    /**
     * Prevents search forms from being submitted.
     *
     * Attaches event listeners to each autocomplete search forms to intercept and prevent form submission.
     */
    function forbidSubmittingForm() {
        // Retrieve all search forms.
        let searchForms = document.querySelectorAll(`.${autocompleteSelector}`);

        // Iterate through forms NodeList.
        Array.from(searchForms).forEach(form => {
            form.addEventListener('submit', (event) => {
                // Prevent the form from being submitted.
                event.preventDefault();
            });
        });
    }

    /**
     * Processes and adds autocomplete sources from Algolia configurations.
     *
     * Iterates over the keys of the Algolia sources object, retrieving each source's configuration
     * and applying it through a function designed to add these sources to the autocomplete functionality.
     * This enables dynamic configuration of autocomplete sources based on the Algolia settings.
     */
    function processAutocompleteSources() {
        // Accessing Algolia's autocomplete source configurations.
        const algoliaSources = algolia.autocomplete.sources;

        // Iterating over each source in the Algolia sources object.
        Object.keys(algoliaSources).forEach((sourceKey) => {
            // Retrieve the configuration for the current source by its key.
            const config = algoliaSources[sourceKey];

            // Add the source to the autocomplete functionality using the retrieved configuration.
            addSource(config);
        });
    }

    /**
     * Adds a single autocomplete source based on provided configuration.
     *
     * Constructs a source configuration object that includes the Algolia hits source
     * and templates for the header and suggestion. This configuration is then added
     * to a global sources array, contributing to the overall autocomplete setup.
     *
     * @param {Object} config The configuration object for the autocomplete source, containing
     *                        keys for templates and other settings.
     */
    function addSource(config) {
        // Prepare the suggestion template using the specified template in the config.
        const suggestionTemplate = wp.template(config['tmpl_suggestion']);

        // Construct the source configuration, including Algolia hit sources and custom templates.
        const sourceConfig = {
            source: createAlgoliaHitsSource(config), // Create the source for Algolia hits.
            templates: {
                header: createHeaderTemplate(config), // Generate the header template.
                suggestion: createSuggestionTemplate(suggestionTemplate) // Generate the suggestion template.
            }
        };

        // Add the constructed source configuration to the global sources array.
        sources.push(sourceConfig);
        // Each call to this function dynamically extends the autocomplete functionality.
    }

    /**
     * Creates an Algolia hits source configuration.
     *
     * Generates a configuration object for retrieving Algolia search hits, tailored according to
     * the provided settings. This includes configuring the number of hits per page, snippet attributes
     * for highlighting, and tags for marking highlighted sections in search results.
     *
     * @param {Object} config The configuration object with settings such as index name, maximum suggestions,
     *                        and other options for hit retrieval and presentation.
     * @returns {Object} A configured Algolia hits source suitable for use in autocomplete sources.
     */
    function createAlgoliaHitsSource(config) {
        // Initializes the Algolia search index based on the provided index name.
        const index = client.initIndex(config['index_name']);

        // Returns the Algolia hits source configuration.
        return algoliaHitsSource(index, {
            hitsPerPage: config['max_suggestions'], // Sets the maximum number of suggestions per page.
            attributesToSnippet: ['content:10'], // Configures attributes to be snippeted and their length.
            highlightPreTag: '__ais-highlight__', // Sets the tag to prepend to highlighted sections.
            highlightPostTag: '__/ais-highlight__' // Sets the tag to append to highlighted sections.
            // These highlight tags are essential for visually distinguishing search terms within the results.
        });
    }

    /**
     * Creates a header template function for autocomplete suggestions.
     *
     * Returns a function that, when called, generates a header template for the autocomplete
     * dropdown. This template is dynamically populated with a label specified in the config object.
     * Utilizes WordPress's templating function (`wp.template`) for generating HTML based on a template
     * script tag and Underscore.js's `_.escape` function to ensure the label text is safely encoded.
     *
     * @param {Object} config The configuration object containing the label for the header.
     * @returns {Function} A function that returns the HTML string for the header template when invoked.
     */
    function createHeaderTemplate(config) {
        // Returns a function that generates the header template.
        return function() {
            // Use WordPress's templating system to generate the header template.
            // The label is safely encoded to prevent XSS attacks by escaping HTML special characters.
            return wp.template('autocomplete-header')({
                label: _.escape(config['label']) // Escape the label to ensure HTML safety.
            });
        };
    }

    /**
     * Creates a suggestion template function for autocomplete suggestions.
     *
     * Generates a function that processes and applies a given template to a search hit. It checks if the
     * hit has been previously processed to avoid re-escaping data, ensuring data is safely handled before
     * applying the template. This approach enhances security by preventing cross-site scripting (XSS)
     * vulnerabilities.
     *
     * @param {Function} suggestionTemplate The template function to be applied to each hit.
     * @returns {Function} A function that takes a search hit, processes it if not already done, and returns
     *                     the result of applying the suggestionTemplate to it.
     */
    function createSuggestionTemplate(suggestionTemplate) {
        // Returns a function designed to process and apply the suggestion template to a search hit.
        return function(hit) {
            // Check if the hit has already been processed (escaped) to prevent double-processing.
            if (!hit.escaped) {
                processHit(hit); // Process the hit for safety before applying the template.
            }
            // Apply the suggestion template to the processed hit and return the generated markup.
            return suggestionTemplate(hit);
        };
    }

    /**
     * Processes a search hit to escape HTML content, format dates, and highlight search results.
     *
     * Marks the hit as escaped to prevent reprocessing, formats the post date into a more readable format,
     * and applies highlighting to both the highlight results and snippet results. This ensures that the
     * data displayed to the user is safe, accurately represented, and emphasizes the search query's relevance.
     *
     * @param {Object} hit The search hit object containing data to be processed.
     */
    function processHit(hit) {
        // Mark the hit as already processed to prevent redundant escaping.
        hit.escaped = true;

        // Format the post date for readability.
        const postDate = new Date(hit.post_date_formatted);
        hit.post_date_formatted = `${postDate.getMonth() + 1}/${postDate.getDate()}/${postDate.getFullYear()}`;

        // Apply highlighting to the search results for better visual feedback.
        highlightSearchResults(hit._highlightResult);
        highlightSearchResults(hit._snippetResult);
        // The use of highlighting functions underscores the importance of making search results
        // visually distinct and easier for users to understand the relevance of their query.
    }

    /**
     * Highlights search results by escaping HTML and adding emphasis tags.
     *
     * Iterates over search result items, escaping HTML content for security and replacing custom
     * highlight tags with `<em>` tags to visually highlight search terms. This process ensures that
     * highlighted text is safely rendered in the browser, preventing XSS attacks while clearly
     * indicating search matches.
     *
     * @param {Object} results The search results object containing items to highlight.
     */
    function highlightSearchResults(results) {
        // Iterate over each key in the results object.
        for (let key in results) {
            // Check if the current item is a string and requires processing.
            if (typeof results[key].value === 'string') {
                // Escape HTML content in the value for security.
                results[key].value = _.escape(results[key].value)
                    // Replace custom highlight start tags with `<em>` for emphasis.
                    .replace(/__ais-highlight__/g, '<em>')
                    // Replace custom highlight end tags with `</em>` to close emphasis.
                    .replace(/__\/ais-highlight__/g, '</em>');
                // This approach ensures that the text is both safe to display and visually
                // differentiated where search terms match, enhancing user experience.
            }
        }
    }

    /**
     * Initializes Algolia autocomplete on search inputs.
     *
     * This function configures and applies Algolia's autocomplete functionality to
     * specified search input elements, enhancing the search experience by adding features
     * such as debugging support, focus-based activation, and custom empty result templates.
     */
    function initializeSearchInputs() {
        // Configuration for Algolia's autocomplete feature.
        let config = {
            debug: algolia.debug,
            hint: false,
            openOnFocus: true,
            appendTo: '.js-autocomplete-container',
            templates: {
                empty: wp.template('autocomplete-empty')
            }
        };

        // Loop through each search input to apply the autocomplete functionality.
        searchInputs.forEach((input) => {
            // Instantiate autocomplete.js for the current input element.
            algoliaAutocomplete(input, config, sources);
        });
    }

    //
    document.addEventListener('DOMContentLoaded', initAlgoliaCustomization);
})();
