import { A11y, Grid, Navigation, Pagination } from 'swiper/modules';

function filterA1() {
    const FWP = window.FWP || {};
    const FWP_HTTP = window.FWP_HTTP || {};
    const filters = document.querySelectorAll('[data-module="filterA1"]');

    /**
     * scroll to the top of the Filter block on pagination.
     */
    (function () {
        filters.forEach((filter) => {
            filter.addEventListener('click', clickHandler);
        });

        function clickHandler(event) {
            const target = event.target;
            // Check if the target element is the pager link and is within the filter block container
            const pagerLink = target.closest('.facetwp-page');
            const filter = target.closest('.js-filter-wrapper');

            // Do nothing if the target is not a pager link or not within the filter block container
            if (!pagerLink || !filter) {
                return;
            }

            const anchor = filter.querySelector('.js-scroll-anchor');
            if (anchor) {
                // Scroll to the top of the block
                anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
            } else {
                filter.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    })();

    /**
     * Reset Filters.
     */
    (function () {
        const resetBtns = document.querySelectorAll('.js-reset-filters');

        resetBtns.forEach((button) => {
            button.addEventListener('click', resetClickHandler);
        });

        function resetClickHandler() {
            const FWP = window.FWP || {};
            if (typeof FWP === 'object' && typeof FWP.reset === 'function') {
                FWP.reset();
            }
        }
    })();

    /**
     * Add loader.
     */
    (function () {
        const filterWrappers = document.querySelectorAll('.js-filter-wrapper');

        /**
         * Adds a loading overlay to elements when the FacetWP facets are refreshed.
         * This overlay appears only after the initial page load.
         *
         * @author Dreamers of Day
         */
        function refreshEventHandler() {
            if (!FWP.loaded) {
                return;
            }
            /**
             * Creates and configures a loader element to indicate ongoing operations.
             */
            const loaderSpan = document.createElement('span');
            loaderSpan.classList.add(
                'h-loader',
                '!opacity-100',
                '!absolute',
                'top-0',
                'left-0',
                'w-full',
                'h-full',
                'z-10',
            );

            // Iterate over each filter wrapper to set its height explicitly and append the loader.
            filterWrappers.forEach((template) => {
                // Obtain and fix the height of the template to prevent layout shift.
                const height = template.clientHeight;
                template.style.height = `${height}px`; // Set the height to prevent content shift.

                // Append the loader span to the current template.
                template.appendChild(loaderSpan.cloneNode()); // Use cloneNode to ensure each template gets a unique loader.
            });
        }

        /**
         * Removes a loading overlay from elements when the FacetWP facets have finished refreshing.
         * This action restores the elements to their original state, removing any visual indication of loading.
         *
         * @author Dreamers of Day
         */
        function loadedEventHandler() {
            if (!FWP.loaded) {
                return;
            }
            /**
             * Iterates over each filter wrapper to remove the loading overlay.
             */
            filterWrappers.forEach((template) => {
                // Find the loader span within the current template.
                const loaderSpan = template.querySelector('.h-loader');

                // Remove the loader span from the template if it exists.
                if (loaderSpan) {
                    template.removeChild(loaderSpan);
                }

                // Reset the template's height to its default (auto).
                template.style.height = ''; // Resets the height.
            });
            toggleResetButtonVisibility();
        }

        // Register Event Listeners.
        document.addEventListener('facetwp-refresh', refreshEventHandler);
        document.addEventListener('facetwp-loaded', loadedEventHandler);
    })();

    document.addEventListener('facetwp-loaded', buildFakeSelectFacet);
    // on resize
    window.addEventListener('resize', function () {
        if (window.innerWidth < 900) {
            updateFakeSelect($('.facetwp-facet[data-name="filter_a_posts"]'));
        }
    });

    function buildFakeSelectFacet() {
        if (!FWP.loaded) {
            return;
        }

        let selectExists =
            $('.o-filter-a-1__wrapper .facetwp-type-fake').length > 0;
        // set the value of the new filter to the value of the cloned filter
        setTimeout(() => {
            if (!selectExists) {
                let cloned = $(
                    '.o-filter-a-1__wrapper .facetwp-facet[data-name="filter_a_posts"]',
                );
                let new_filter = jQuery('<ul/>', {
                    'data-name': cloned.data('name'),
                    class: 'facetwp-facet facetwp-type-fake js-dropdown-list',
                });

                $.each(cloned.children(), function (index, option) {
                    new_filter.append(
                        jQuery('<li/>', {
                            'data-value':
                                index === 0 ? 'All' : $(option).data('value'),
                            text:
                                index === 0
                                    ? 'All'
                                    : $(option)
                                          .find('.facetwp-display-value')
                                          .text(),
                        }),
                    );
                });

                new_filter.wrap(
                    '<div class="facetwp-type-fake-wrapper 900:hidden" />',
                );

                $('<a href="#" class="dropdown-link">All</a>').prependTo(
                    new_filter.parent(),
                );

                cloned
                    .closest('.o-filter-a-1__facets-wrapper')
                    .append(new_filter.parent());

                updateFakeSelect(cloned);

                let list = $('.js-dropdown-list');
                let link = $('.dropdown-link');
                link.on('click', link, function (e) {
                    e.preventDefault();
                    list.slideDown(200);
                    if (
                        list
                            .closest('.facetwp-type-fake-wrapper')
                            .hasClass('open')
                    ) {
                        list.slideUp(200);
                        list.closest('.facetwp-type-fake-wrapper').removeClass(
                            'open',
                        );
                        return;
                    }
                    list.closest('.facetwp-type-fake-wrapper').addClass('open');
                });

                $('.js-dropdown-list li').on('click', function () {
                    let text = $(this).html();
                    link.html(text);

                    if ($(this).data('value') === 'All') {
                        FWP.reset();
                        FWP_HTTP.get = {};
                        FWP.fetchData();
                    } else {
                        $(
                            '.facetwp-type-radio .facetwp-radio[data-value="' +
                                $(this).data('value') +
                                '"]',
                        ).trigger('click');
                    }

                    list.slideUp(200);
                    list.closest('.facetwp-type-fake-wrapper').removeClass(
                        'open',
                    );
                });
            }
        }, 100);
    }

    function updateFakeSelect(parentElement) {
        let queryString = new URL(location.href).searchParams.get(
            '_filter_a_posts',
        );

        if (queryString !== null) {
            let selected = parentElement
                .find('.facetwp-radio[data-value="' + queryString + '"]')
                .find('.facetwp-display-value')
                .text();

            $('.facetwp-type-fake-wrapper')
                .find('.dropdown-link')
                .html(selected);
        }
    }

    /**
     * Choices JS
     */
    (function () {
        const Choices = window.Choices || {};
        function choicesJSInitialize() {
            if (!FWP.loaded) {
                return;
            }
            const selectBoxes = document.querySelectorAll('.facetwp-dropdown');

            selectBoxes.forEach((box) => {
                this.chartSelectChoice = new Choices(box, {
                    shouldSort: false,
                    itemSelectText: '',
                    allowHTML: true,
                    searchEnabled: false,
                });
            });
        }

        // Register Event Listeners.
        document.addEventListener('facetwp-loaded', choicesJSInitialize);
    })();

    /**
     *
     */
    (function () {
        document.dispatchEvent(new Event('facetwp-loaded'));
        //
        const filters = document.querySelectorAll('.js-filter-wrapper');
        filters.forEach((filter) => {
            filter.classList.add('initialized');
            toggleResetButtonVisibility();
        });
    })();

    /**
     * Toggles the visibility of the reset button based on the active filters.
     *
     * This function checks if there are any active filters by examining the FWP.facets object.
     * If there are active filters, it shows the reset buttons. Otherwise, it hides them.
     */
    function toggleResetButtonVisibility() {
        // Check if any filters are active by looking at the FWP.facets object
        let hasActiveFilters = false;
        for (var key in FWP.facets) {
            if (FWP.facets[key].length > 0) {
                hasActiveFilters = true;
                break;
            }
        }

        // Show or hide the reset button based on whether any filters are active
        const resetBtns = document.querySelectorAll('.js-reset-filters');

        // Toggle the 'reset_active' class on each reset button based on the presence of active filters
        resetBtns.forEach((resetButton) => {
            resetButton.classList.toggle('reset_active', hasActiveFilters);
        });
    }

    /**
     * Swiper slider
     */

    let swiper;
    let args = {
        modules: [A11y, Navigation, Pagination, Grid],
        slidesPerView: 1,
        grid: {
            rows: 1,
            fill: 'row',
        },
        navigation: {
            nextEl: '.next-slide',
            prevEl: '.prev-slide',
        },
        spaceBetween: 0,
        observer: true,
        observeParents: true,
        watchSlidesProgress: true,
        pagination: {
            el: '.js-swiper-pagination',
            clickable: true,
            bulletElement: 'button',
            dynamicBullets: true,
            dynamicMainBullets: 1,
            renderBullet: function (index, className) {
                const bullets = this.snapGrid.length;
                let decorativeElements = '';
                if (index === 0) {
                    decorativeElements += `<span class="swiper-pagination-bullet-deco swiper-pagination-bullet-deco-left-1"></span>`;
                    decorativeElements += `<span class="swiper-pagination-bullet-deco swiper-pagination-bullet-deco-left-2"></span>`;
                }
                if (index === bullets - 1) {
                    decorativeElements += `<span class="swiper-pagination-bullet-deco swiper-pagination-bullet-deco-right-1"></span>`;
                    decorativeElements += `<span class="swiper-pagination-bullet-deco swiper-pagination-bullet-deco-right-2"></span>`;
                }
                return `<button class="${className}">${decorativeElements}</button>`;
            },
        },
        a11y: {
            paginationBulletMessage: 'Go to slide {{index}}',
            slideLabelMessage: '{{index}} / {{slidesLength}}',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                grid: {
                    rows: 3,
                    fill: 'row',
                },
            },
            600: {
                slidesPerView: 2,
                grid: {
                    rows: 3,
                    fill: 'row',
                },
            },
            1024: {
                slidesPerView: 3,
                grid: {
                    rows: 3,
                    fill: 'row',
                },
            },
            1200: {
                slidesPerView: 3,
                grid: {
                    rows: 3,
                    fill: 'row',
                },
            },
        },
        on: {
            transitionStart: (swiper) => {
                let height = 0;
                let counter = 0;
                for (let i = 0; i < swiper.slides.length; i++) {
                    let slide = swiper.slides[i];
                    if ([...slide.classList].includes('swiper-slide-visible')) {
                        counter = counter + 1;
                        if (window.innerWidth < 600) {
                            // do nothing here.
                        } else if (window.innerWidth < 1024) {
                            if (counter % 2 !== 1) {
                                continue;
                            }
                        } else {
                            if (counter % 3 !== 1) {
                                continue;
                            }
                        }

                        height +=
                            slide.offsetHeight +
                            parseInt(
                                window
                                    .getComputedStyle(slide)
                                    .getPropertyValue('margin-bottom'),
                            );
                    }
                }
                if (height > 0) {
                    swiper.wrapperEl.style.height = `${height}px`;
                }
            },
        },
    };
}

export default filterA1;
