import './global/js/util/modernizr';
import './global/js/util/polyfills';
import './style.scss';
import './global/js/core/core';
import './global/js/vendors/algolia/autocomplete';

/*
 * Animations
 */
import accordionMobileInit from './global/js/accordions/accordion-mobile-init';
import cursorEffects from './global/js/animations/cursor-effects';
import staggerAnimation from './global/js/animations/gsap/stagger-text';
import numberCount from './global/js/animations/number-count';
import parallaxScroll from './global/js/animations/parallax-light';
import progressBar from './global/js/animations/progress';
import letterEffects from './global/js/animations/splittext';
import accordionInit from './global/js/misc/accordion/accordion-init';
/*
 * Misc
 */
import dropdownInit from './global/js/misc/dropdown/dropdown-init';
import externalLinks from './global/js/misc/external-links/external-links';
import customMicromodalCall from './global/js/misc/micromodal/micromodal';
import swiperInit from './global/js/misc/swiper/swiper-init';
import tableInit from './global/js/misc/table/table-init';
import tippyTooltips from './global/js/misc/tippy/tippy';
import videoInit from './global/js/misc/video/video-init';
/*
 * Atoms
 */
import scrollToTop from './patterns/01-atoms/misc/scroll-to-top-a/scroll-to-top-a-1/scroll-to-top-a-1';
/*
 * Molecules
 */
import multiselectDropdown from './patterns/02-molecules/dropdowns/terms-multiselect-a/terms-multiselect-a-1/terms-multiselect-a-1';
import galleryA from './patterns/02-molecules/galleries/gallery-a/gallery-a-1/gallery-a-1';
import mapBlock from './patterns/02-molecules/maps/map-a/map-a-1';
import modalVideoA1 from './patterns/02-molecules/modals/modal-video-a/modal-video-a-1';
import hamburger from './patterns/02-molecules/nav/hamburger-a/hamburger-a-1/hamburger-a-1';
import toggleMobileNavigationDropdowns from './patterns/02-molecules/nav/nav-mobile-a/nav-mobile-a-1';
import navPrimaryA1 from './patterns/02-molecules/nav/nav-primary-a/nav-primary-a-1';
import postHeadingNavigationA1 from './patterns/02-molecules/nav/post-heading-navigation-a/post-heading-navigation-a-1.js';
import searchBar from './patterns/02-molecules/search/search-bar-a/search-bar-a-1/search-bar-a-1';
import slideOut from './patterns/02-molecules/slide-outs/slide-out-a/slide-out-a-1/slide-out-a-1';
import tabs from './patterns/02-molecules/tabs/tabs-a/tabs-a-1';
import widgetTranslationA from './patterns/02-molecules/widgets/widget-translation-a/widget-translation-a-1/widget-translation-a-1';
import widgetTranslationB from './patterns/02-molecules/widgets/widget-translation-b/widget-translation-b-1/widget-translation-b-1';
import cardsA2 from './patterns/03-organisms/repeatable-custom/cards-a/cards-a-2/cards-a-2';
import cardsC1 from './patterns/03-organisms/repeatable-custom/cards-c/cards-c-1/cards-c-1';
import cardsD1 from './patterns/03-organisms/repeatable-custom/cards-d/cards-d-1/cards-d-1';
/*
 * Plop Entry (do not remove)
 */
/// /DO NOT ERASE OR PLOP IMPORTING WILL FAIL
/// /IMPORT FROM PLOP
import cardsG1 from './patterns/03-organisms/repeatable-custom/cards-g/cards-g-1/cards-g-1';
import collapsableA1 from './patterns/03-organisms/repeatable-custom/collapsable-a/collapsable-a-1/_collapsable-a-1';
import filterType1 from './patterns/03-organisms/repeatable-custom/filter-a/filter-a-1/filter-a-1';
import filterA1 from './patterns/03-organisms/repeatable-custom/filter-a/filter-a-1/filter-a-1';
import filterB1 from './patterns/03-organisms/repeatable-custom/filter-b/filter-b-1/filter-b-1';
/*
 * Organisms
 */
import menuA1 from './patterns/03-organisms/repeatable-custom/menu-a/menu-a-1/menu-a-1';
import specialityLinksImagesA from './patterns/03-organisms/repeatable-custom/speciality-links-images-a/speciality-links-images-a-1/speciality-links-images-a';
import headerInit from './patterns/03-organisms/single/header/header-init';
import heroSpecialty1 from './patterns/03-organisms/single/hero/hero-specialty-1/hero-specialty-1';
import heroSpecialty2 from './patterns/03-organisms/single/hero/hero-specialty-2/hero-specialty-2';
import heroSpecialty4 from './patterns/03-organisms/single/hero/hero-specialty-4/hero-specialty-4';
import heroSpecialty5 from './patterns/03-organisms/single/hero/hero-specialty-5/hero-specialty-5';

/*
 * Module Caller
 *
 * functions below are only run if the component exists (verified through a "data-module" in the html)
 * in the ajax container or page. Barba.js instantiates the functions below on container reload as well.
 *
 * Place in alphabetical order
 */
const modules = {
    searchBar,
    scrollToTop,
    hamburger,
    heroSpecialty1,
    heroSpecialty2,
    heroSpecialty4,
    heroSpecialty5,
    slideOut,
    modalVideoA1,
    mapBlock,
    galleryA,
    filterType1,
    filterB1,
    multiselectDropdown,
    progressBar,
    postHeadingNavigationA1,
    menuA1,
    specialityLinksImagesA,
    tabs,
    cardsA2,
    cardsC1,
    cardsD1,
    filterA1,
    collapsableA1,
    /// /DO NOT ERASE OR PLOP IMPORTING WILL FAIL
    /// /MODULES FROM PLOP
    cardsG1,
};

/*
 * Fire JavaScript functions by if the associated "data-module" exists on the page.
 */
const calledModulesNames = [];
modules.instantiate = function (elem) {
    const module = elem.dataset.module;

    if (module === undefined) {
        throw 'Module not defined (use data-module="")';
    } else if (module in modules) {
        if (elem.dataset.initialized === 'true') {
            return;
        }
        new modules[module](elem);
        elem.dataset.initialized = true;
    } else {
        throw `Module '${module}' not found`;
    }
};

window.onload = (event) => {
    widgetTranslationA();
    widgetTranslationB();
    externalLinks();
    customMicromodalCall();

    headerInit();
    dropdownInit();
    swiperInit();
    tableInit();
    accordionInit();
    accordionMobileInit();

    staggerAnimation();
    toggleMobileNavigationDropdowns();
    navPrimaryA1();
    tippyTooltips();
    numberCount();
    /*
     * Loops through component functions that should only run if the component exists in the ajax container or page.
     * The actual check is performed through "data-module";
     */

    document.querySelectorAll('[data-module]').forEach(function (el) {
        if (calledModulesNames.indexOf(el.dataset.module) === -1) {
            modules.instantiate(el);
            calledModulesNames.push(el.dataset.module);
        }
    });

    videoInit();

    /*
     * GSAP based animations. Hide if not used.
     */
    // cursorEffects($);
    // letterEffects($);
};

document.addEventListener('scroll', () => {
    parallaxScroll();
});
