/**
 * Attaches mouseover event listeners to link elements to control a swiper instance.
 * When a link is hovered over, the associated swiper updates and slides to the index of the hovered link.
 *
 * @author Dreamers of Day
 */
function specialityLinksImagesA() {
    // Define class hooks for DOM selections.
    const hooks = {
        specialityLinksImages: 'js-speciality-links-images',
        slider: 'js-speciality-links-slider',
        linkWrapper: 'js-speciality-links-link-wrapper',
    };

    // Select all blocks using the 'js-speciality-links-images' class.
    const specialityWrapper = document.querySelectorAll(
        `.${hooks.specialityLinksImages}`,
    );

    // Iterate over each block instance found.
    specialityWrapper.forEach((blockInstance) => {
        // Attempt to find a slider within the block instance.
        const slider = blockInstance.querySelector(`.${hooks.slider}`);

        if (slider) {
            // Select all link wrapper elements within the block instance and attach mouseover events.
            blockInstance
                .querySelectorAll(`.${hooks.linkWrapper}`)
                .forEach((link, i) => {
                    link.addEventListener('mouseover', () => {
                        // On mouseover, check if swiper is defined and then update and slide to the current link's index.
                        if (slider.swiper) {
                            slider.swiper.update();
                            slider.swiper.slideTo(i);
                        }
                    });
                });
        }
    });
}

// Export the function as a default export.
export default specialityLinksImagesA;
